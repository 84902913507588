import Home from './Home';
import '../css/App.css';
import Prices from './Prices';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Header from './Header';
import { useEffect, useRef, useState } from 'react';

function App() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [sidebarShow, setSidebarToggle] = useState(false);
  const navigate = useNavigate();
  const scrollToElem = async (id) => {
    if (window.location.pathname === '/prices') {
      navigate(`/${id}`)
    } else {
      switch (id) {
        default:
        case 'home':
        case 'sd_home':
          let rect1 = ref1.current.getBoundingClientRect();
          window.scrollTo(0, rect1.top + window.scrollY - 100)
          break;
        case 'services':
        case 'sd_services':
          let rect2 = ref2.current.getBoundingClientRect();
          window.scrollTo(0, rect2.top + window.scrollY - 100)
          break;
        case 'advantages':
        case 'sd_advantages':
          let rect3 = ref3.current.getBoundingClientRect();
          window.scrollTo(0, rect3.top + window.scrollY - 100)
          break;
        case 'contacts':
        case 'sd_contacts':
          let rect4 = ref4.current.getBoundingClientRect();
          window.scrollTo(0, rect4.top + window.scrollY - 100)
          break;
        case 'reviews':
        case 'sd_reviews':
          let rect5 = ref5.current.getBoundingClientRect();
          window.scrollTo(0, rect5.top + window.scrollY - 100)
          break;
      }
    }
  }
  useEffect(() => {
    let id = window.location.pathname.substring(1, window.location.pathname.length);
    if (['home', 'sd_home', 'services', 'sd_services', 'advantages', 'sd_advantages', 'contacts', 'sd_contacts', 'reviews', 'sd_reviews'].includes(id)) {
      scrollToElem(id)
    }
  }, [window.location.href])
const needSidebarToggle = (need) => {
  setSidebarToggle(need);
}
  return (
    <div className="App">
      <Header scrollToElem={scrollToElem} sidebarToggle={sidebarShow} setSidebarToggle={needSidebarToggle}/>
        <Routes>
          <Route path="/*" element={<Home ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} setSidebarToggle={needSidebarToggle} scrollToElem={scrollToElem}/>} />
          <Route path="/prices" element={<Prices ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} ref5={ref5} setSidebarToggle={needSidebarToggle}/>} />
        </Routes>
    </div>
  );
}

export default App;
