import '../css/FirstSection.css';

function FirstSection(props) {
  return (
    <div ref={props.sectionRef} className="firstSection">
        {/* <span className='allDay'>Качество и аккуратность</span> */}
        <span className='outDoorCut'>Работаем</span>
        <div className='orangeText'>без выходных</div>
        <div className='orangeText fts27'>Принимаем заказы 24/7</div>
        
        <button className='button' onClick={() => props.scrollToElem('contacts')}>ЗАКАЗАТЬ РЕЗКУ</button>
        <div className='sectionFoot'>
            <span>БЫСТРО</span>
            <span>НАДЕЖНО</span>
            <span>АККУРАТНО</span>
        </div>
    </div>
  );
}

export default FirstSection;
