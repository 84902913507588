import '../css/ThirdSection.css';

function ThirdSection(props) {
  return (
    <div ref={props.sectionRef} className="thirdSection">
        <span className='advantageHeader'>НАШИ ПРЕИМУЩЕСТВА</span>
        <div className='advantageRow'>
            <div className='advantage'>
                <img src='./url.png' srcSet='./url@2x.png 2x,./url@3x.png 3x' alt='Работаем 24 часа без выходных' className='advantageImg'/>
                <span className='m-t-32'>Принимаем заказы</span>
                <span>24/7</span>
            </div>
            <div className='advantage'>
                <img src='./high-quality.png' srcSet='./high-quality@2x.png 2x,./high-quality@3x.png 3x' alt='Качество и аккуратность' className='advantageImg'/>
                <span className='m-t-32'>100% заводское качество</span>
            </div>
            <div className='advantage'>
                <img src='./circular-saw.png' srcSet='./circular-saw@2x.png 2x,./circular-saw@3x.png 3x' alt='Резка любой сложности' className='advantageImg'/>
                <span className='m-t-32'>Резка любой</span>
                <span>сложности</span>
            </div>
            <div className='advantage'>
                <img src='./delivery-van.png' srcSet='./delivery-van@2x.png 2x,./delivery-van@3x.png 3x' alt='Забор и доставка Погрузка и выгрузка' className='advantageImg'/>
                <span className='m-t-32'>Забор и доставка</span>
                <span>Погрузка и выгрузка</span>
            </div>
        </div>
    </div>
  );
}

export default ThirdSection;