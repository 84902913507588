import '../css/SecondSection.css';
import service1 from '../service1.jpg';
import service2 from '../service2.jpg';
import service3 from '../service3.jpg';
import service4 from '../service4.jpg';
import service5 from '../service5.jpg';
import service6 from '../service6.jpg';
import service7 from '../service7.jpg';
import service8 from '../service8.jpg';
import service9 from '../service9.jpg';
import service10 from '../service10.jpg';
import service11 from '../service11.jpg';
import service12 from '../service12.jpg';
import service13 from '../service13.jpg';
import service14 from '../service14.jpg';
import service15 from '../service15.jpg';
import service16 from '../service16.jpg';


function SecondSection(props) {
  return (
    <div ref={props.sectionRef} className="secondSection">
        <span className='subHeader'>НАШИ УСЛУГИ</span>
        <div className='row'>
            <div className='divider'>
                <div className='optionImgContainer'>
                    <img src={service1} alt='Прямая резка плитки, керамики, мрамора, гранита'/>
                    <div className='description'>
                        <span className='firstLine'>Прямая резка плитки, керамики, мрамора, гранита</span>
                        <span className='secondLine'>от 150 руб. за п/м</span>
                    </div>
                </div>
                <div className='optionImgContainer'>
                    <img src={service2} alt='Резка под углом 45 градусов'/>
                    <div className='description'>
                        <span className='firstLine'>Резка плитки под углом 45 градусов</span>
                        <span className='secondLine'>от 350р. за п/м</span>
                    </div>
                </div>
            </div>
            <div className='divider'>
                <div className=' optionImgContainer'>
                    <img src={service3} alt='Резка крупноформатной плитки'/>
                    <div className='description'>
                        <span className='firstLine'>Резка крупноформатной плитки</span>
                        <span className='secondLine'>заказ от 5000р</span>
                    </div>
                </div>
                <div className=' optionImgContainer'>
                    <img src={service4} alt='Изготовление плинтусов'/>
                    <div className='description'>
                        <span className='firstLine'>Изготовление плинтусов</span>
                        <span className='secondLine m-t-45'>от 600р. за п/м</span>
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className=' optionImgContainer'>
                <img src={service5} alt='Изготовление мозаики'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление мозаики</span>
                    <span className='secondLine m-t-45'>от 200р. за п/м</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service6} alt='Сверление отверстий'/>
                <div className='description'>
                    <span className='firstLine'>Сверление отверстий</span>
                    <span className='secondLine m-t-45'>от 100р. за шт.</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service7} alt='Изготовление фальшмозаики'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление фальшмозаики</span>
                    <span className='secondLine m-t-45'>от 130р. за п/м</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service8} alt='Изготовление панно'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление панно</span>
                    <span className='secondLine'> от 10000р.</span>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className=' optionImgContainer'>
                <img src={service9} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Снятие фасок</span>
                    <span className='secondLine m-t-45'>от 50р. за п/м</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service10} alt='Изготовление подоконников ступеней с закруглением'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление подоконников ступеней с закруглением</span>
                    <span className='secondLine m-t-45'>По договоренности</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service11} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Резка октагона</span>
                    <span className='secondLine m-t-45'>от 50р. за шт.</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service12} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Резка мозаики под углом 45 градусов</span>
                    <span className='secondLine m-t-45'>от 500р. за п/м.</span>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className=' optionImgContainer'>
                <img src={service13} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление островков</span>
                    <span className='secondLine m-t-45'>По договоренности</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service14} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление столов</span>
                    <span className='secondLine m-t-45'>По договоренности</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service15} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление столешниц</span>
                    <span className='secondLine m-t-45'>По договоренности</span>
                </div>
            </div>
            <div className=' optionImgContainer'>
                <img src={service16} alt='options'/>
                <div className='description'>
                    <span className='firstLine'>Изготовление лестниц с проклейкой</span>
                    <span className='secondLine m-t-45'>По договоренности</span>
                </div>
            </div>
        </div>
    </div>
  );
}

export default SecondSection;
