import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import Reviews from './Reviews';
import { useEffect } from 'react';

function Home(props) {
    useEffect(() => {
        props.setSidebarToggle(false)
    }, [])
  return (
    <>
      <div className='main'>
        <FirstSection sectionRef={props.ref1} scrollToElem={props.scrollToElem}/>
        <SecondSection sectionRef={props.ref2}/>
        <ThirdSection sectionRef={props.ref3}/>
        <FourthSection sectionRef={props.ref4}/>
        <Reviews sectionRef={props.ref5}/>
      </div>
    </>
  );
}

export default Home;
