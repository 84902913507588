import { useEffect, useRef, useState } from 'react';
import '../css/header.css';
import phoneSvg from '../phone.svg'
import { Link } from 'react-router-dom';

function Header(props) {
  const [isSidebarOpen, setSidebarToggle] = useState(false);
  const sidebar = useRef();
  useEffect(() => {
    if (props.sidebarToggle && isSidebarOpen) {
      sidebarToggle();
    }
  }, [props.sidebarToggle])
  const sidebarToggle = () => {
    setSidebarToggle(!isSidebarOpen);
    props.setSidebarToggle(isSidebarOpen)
    if (sidebar.current.classList.contains('hide')) {
      sidebar.current.classList.add('sidebar');
      sidebar.current.classList.remove('hide');
    } else {
      sidebar.current.classList.add('hide');
      sidebar.current.classList.remove('sidebar')
    }
  }
  const sectionSelect = (e) => {
    if (sidebar.current.classList.contains('sidebar')) {
      setSidebarToggle(!isSidebarOpen);
      sidebar.current.classList.add('hide');
      sidebar.current.classList.remove('sidebar')
    }
    if(e && e.target) {
      props.scrollToElem(e.target.id)
    }
  }
  return (
    <div className="header">
        {
          !isSidebarOpen && <img src='./logo.png' srcSet='./logo@2x.png 2x,./logo@3x.png 3x' alt='mosplitrez' className='mosImg' id='home' onClick={sectionSelect}/>
        }
        <div className='sections'>
          <div className='section' id='reviews' onClick={sectionSelect}>Отзывы</div>
          <div className='section' id='services' onClick={sectionSelect}>Наши услуги</div>
          <div className='section' id='advantages' onClick={sectionSelect}>Наши преимущества</div>
          <div className='section' ><Link to="/prices">Цены</Link></div>
          <div className='section' id='contacts' onClick={sectionSelect}>Контакты</div>
        </div>
        {
          !isSidebarOpen && <img src='./menu.svg' alt='mosplitrez' className='sidebarToggle' onClick={sidebarToggle}/>
        }
        <div className='hide' ref={sidebar}>
            <div className='sidebarRow'>
              <div className='sidebarHead'>
                  <img src='./logo.png' srcSet='./logo@2x.png 2x,./logo@3x.png 3x' alt='mosplitrez' className='mosImg' id='sd_home' onClick={sectionSelect}/>
                  <img src='./close.svg' alt='mosplitrez close' className='sidebarToggle' onClick={sidebarToggle}/>
              </div>
              <div className='sd_sections'>
                <div className='section' id='sd_reviews' onClick={sectionSelect}>Отзывы</div>
                <div className='section' id='sd_services' onClick={sectionSelect}>Наши услуги</div>
                <div className='section' id='sd_advantages' onClick={sectionSelect}>Наши преимущества</div>
                <div className='section'><Link to="/prices">Цены</Link></div>
                <div className='section' id='sd_contacts' onClick={sectionSelect}>Контакты</div>
              </div>
            </div>
          </div>
    </div>
  );
}

export default Header;
