import { useEffect, useState } from 'react';
import '../css/Prices.css';

function Prices(props) {
    let [size, setSize] = useState(20);
    useEffect(() => {
        props.setSidebarToggle(true);
    }, [])
    const setValue = () => {
        let e = document.getElementById("select");
        let value = e.value;
        let text = e.options[e.selectedIndex].value;
        setSize(text / 1)
    }

    let obj = {
        '1': '',
    }
  return (
    <div className="prices">
        <span className='priceHeader'>СТОИМОСТЬ РАБОТ</span>
        <div className='pricesColumn'>
            <div className='highlighted'>
                <span>Минимальная сумма заказа 2000 руб.</span>
                <span>Максимальный формат плитки 180 см.</span>
            </div>
            <div className='priceSection'>
                <span className='subHead'>Прямая резка (цена за п/м)</span>
                <span>Размер плитки  20 -120см – от <span className='ogColor'>150</span> руб.</span>
                <span>​Размер плитки 120 -150см – от <span className='ogColor'>250</span> руб.</span>
                <span>​Размер плитки 150 -160см – от <span className='ogColor'>300</span> руб.</span>
                <span>​Размер плитки 160 -180см – от <span className='ogColor'>350</span>руб.</span>
                <span>Мелкоформатная плитка до 20 см от <span className='ogColor'>50 руб/шт.</span></span>
                <span>Резка мозаики <span className='ogColor'>250 руб/шт.</span></span>
            </div>
            <div className='priceSection'>
                <span className='subHead'>Резка под углом 45°(цена за п/м)</span>
                <span>Размер 20-120см – <span className='ogColor'>350</span> руб</span>
                <span>Размер 120-150см – <span className='ogColor'>450</span> руб.</span>
                <span>Размер 150-160см – <span className='ogColor'>550</span>руб.</span>
                <span>Размер 160-180см – <span className='ogColor'>650</span>руб.</span>
                <span>Мелкоформатная плитка до 20 см от <span className='ogColor'>100 руб/шт.</span></span>
                <span>Резка мозаики <span className='ogColor'>500 руб/шт.</span></span>
            </div>
            <div className='priceSection'>
                <span className='subHead'>Сверление отверстий</span>
                <div>
                    <span>Ø</span>
                    <select id="select" onChange={setValue}>
                        <option value="20" >20</option>
                        <option value="25" >25</option>
                        <option value="30" >30</option>
                        <option value="35" >35</option> 
                        <option value="40" >40</option> 
                        <option value="45" >45</option> 
                        <option value="50" >50</option> 
                        <option value="55" >55</option> 
                        <option value="60" >60</option> 
                        <option value="65" >65</option> 
                        <option value="68" >68</option> 
                        <option value="70" >70</option> 
                        <option value="75" >75</option> 
                        <option value="80" >80</option> 
                        <option value="85" >85</option> 
                        <option value="90" >90</option> 
                        <option value="95">95</option> 
                        <option value="100">100</option> 
                        <option value="105">105</option> 
                        <option value="110">110</option>   
                    </select>
                    <span>мм <span className='ogColor'>{size * 10} руб/шт.</span></span>
                </div>
            </div>
            <div className='priceSection'>
                <span className='subHead'>Дополнительные услуги</span>
                <span>Г-образный вырез – от <span className='ogColor'>800 руб/шт.</span></span>
                <span>Вырезка люка с сохранением – от <span className='ogColor'>2000 руб/шт.</span></span>
                
                <span>П-образный вырез – от <span className='ogColor'>1000 руб/шт.</span></span>
                <span>Фальшмозайка – от <span className='ogColor'>130 руб. п/м</span></span>
                <span>Шлифовка кромки под заводской шов - <span className='ogColor'>50 руб. п/м</span></span>
                <span>Противоскользящие надсечки – от <span className='ogColor'>150 руб. п/м</span></span>
                <span>Закругление - <span className='ogColor'>600 руб. п/м</span></span>
            </div>
        </div>

    </div>
  );
}

export default Prices;
