import { useRef, useState } from 'react';
import '../css/FourthSection.css';
import emailjs from '@emailjs/browser';

function FourthSection(props) {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const setNameValue = (e) => {
        debugger
        setName(e.target.value)
    }
    const setPhoneValue = (e) => {
        setPhone(e.target.value)
    }

    const sendEmail = () => {
        emailjs.send('service_sj3lfyn', 'template_ua1ix2b', {
            name: name,
            phone: phone
        }, {
            publicKey: 'F2YJi_xMrZQ2XVFRb',
          })
        .then((response) => {
            alert('Письмо успешно отправлено')
            console.log('Email sent successfully:', response);
        })
        .catch((error) => {
            console.log('Email send failed:', error);
        });
    }

  return (
    <div ref={props.sectionRef} className="fourthSection">
        <span className='leaveMessageHeader'>Оставьте заявку</span>
        <span className='address'>Мы вам скоро перезвоним</span>
        <div className='formContainer'>
            <div className='inpContainer'>
                <input type='text' className='txtInput' placeholder='Имя' value={name} onChange={setNameValue}/>
                <input type='tel' className='txtInput' placeholder='Телефон' value={phone} onChange={setPhoneValue}/>
            </div>
            <button type='submit' onClick={sendEmail} >Отправить</button>
        </div>
        <div className='contactUsContainer'>
            <span className='contactUsHeader'>КОНТАКТЫ</span>
            <div className='groupContainer'>
                <div className='grp'>
                <div className='group'>
                    <span className='groupName'>АДРЕС:</span>
                    <a target='_blank' href='https://yandex.ru/maps/org/mosplitrez/139823457094/?ll=37.385035%2C55.807840&utm_content=more-reviews&utm_medium=reviews&utm_source=maps-reviews-widget&z=17.12'>
                        <span className='grpTxt'>МКАД, 65-й км, вл 2а,</span>
                        <span className='grpTxt'>район Строгино</span>
                    </a>
                    
                    <span className='groupName m-t-10'>Телефон:</span>
                    <span className='grpTxt'><a href="tel:84952228553">8(495)2228553</a></span>
                    <span className='grpTxt'><a href="tel:89852228553">8(985)2228553</a></span>
                </div>
                <div className='group m-t-10'>
                    <span className='groupName'>АДРЕС:</span>
                    <a target='_blank' href='https://yandex.com/maps/10758/himki/house/leningradskoye_shosse_29_y_kilometr_vl1/Z04YdQRpSUIAQFtvfXV0dnpjYQ==/?ll=37.339076%2C55.957605&z=17.12'>
                        <span className='grpTxt'>Московская область, Химки,</span>
                        <span className='grpTxt'>Ленинградское шоссе, 29-й километр,</span>
                        <span className='grpTxt'>вл1, 31 павильон</span>
                    </a>
                    <span className='groupName m-t-10'>Телефон:</span>
                    <span className='grpTxt'><a href="tel:89313009999">8(931)3009999</a></span>
                </div>
                <div className='group'>
                    <span className='groupName'>Email:</span>
                    <a className='grpTxt' href='mailto:mosplitrez@gmail.com'>mosplitrez@yandex.ru</a>
                </div>
                <div className='iconConatiner'>
                    <a href="https://www.facebook.com/profile.php?id=100054899171374" target="_blank" rel='noreferrer'>
                        <img src='./facebook-svgrepo-com.png' className='logoImg' alt='Mosplitrez facebook' />
                    </a>
                    <a href="https://instagram.com/mosplitrez?igshid=117vc22mvsf8" target="_blank" rel='noreferrer'>
                        <img src='./instagram-outline-svgrepo-com (3).png' className='logoImg' alt='Mosplitrez instagram' />
                    </a>
                </div>
                </div>
            </div>
            
        </div>
    </div>
  );
}

export default FourthSection;