import '../css/Reviews.css';

function Reviews(props) {
  return (
    <div ref={props.sectionRef} className="reviews">
        <span className='reviewHead'>Отзывы клиентов </span>
        <div style={{position:'relative',marginTop:'80px'}}>
            <a
                href="https://yandex.ru/maps/org/mosplitrez/139823457094/?utm_medium=mapframe&utm_source=maps"
                style={{color:'#eee',fontSize:'12px',position:'absolute',top:'0px'}}
            >Мосплитрез
            </a>
            <a
                href="https://yandex.ru/maps/10735/krasnogorsk/category/diamond_cutting/184107583/?utm_medium=mapframe&utm_source=maps"
                style={{color:'#eee',fontSize:'12px',position:'absolute',top:'14px'}}
            >Алмазная резка в Красногорске
            </a>
            <a
                href="https://yandex.ru/maps/10735/krasnogorsk/category/waterjet_cutting/38567213983/?utm_medium=mapframe&utm_source=maps"
                style={{color:'#eee',fontSize:'12px',position:'absolute',top:'28px'}}
            >Гидроабразивная резка в Красногорске
            </a>
            <iframe
                title='Mosplitrez'
                src="https://yandex.ru/maps-reviews-widget/139823457094?comments"
                width="100%"
                height="400"
                frameBorder="1"
                allowFullScreen={true}
                style={{position:'relative',border: 'none',marginTop: '20px',height: '1300px',width: '400px', overflowY: 'scroll', display: 'flex', justifyContent: 'center'}}
            ></iframe>
        </div>
    </div>
  );
}

export default Reviews;
